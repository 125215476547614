<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Détails d'un contact CE : {{ contactWorksCouncil ? contactWorksCouncil.company : '-' }}
    </v-card-title>

    <div class="mt-5 mb-5 mr-5 float-right">
      <v-chip
        label
        color="info"
        class="float-right"
      >
        <strong>Prise de contact le {{ formatDate(contactWorksCouncil.createdAt) }}</strong>
      </v-chip>
    </div>

    <div class="clear"></div>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <h4 class="mb-5">
              Informations entreprise
            </h4>

            <v-text-field
              v-model="entityData.company"
              :rules="companyRules"
              class="mb-5"
              label="Nom de l'entreprise"
              outlined
              dense
              placeholder="Nom de l'entreprise"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.address"
              class="mb-5"
              label="Adresse"
              outlined
              dense
              placeholder="Adresse"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.zipCode"
              class="mb-5"
              label="Code postal"
              outlined
              dense
              placeholder="Code postal"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.city"
              class="mb-5"
              label="Ville"
              outlined
              dense
              placeholder="Ville"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.schedule"
              class="mb-5"
              label="Horaires"
              outlined
              dense
              placeholder="Horaires"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.nbPersons"
              class="mb-5"
              label="Effectifs entreprise"
              outlined
              dense
              placeholder="Effectifs entreprise"
              hide-details
            ></v-text-field>

            <v-select
              v-model="entityData.dutyDays"
              :items="days"
              label="Jours de permanence"
              multiple
              chips
              outlined
            ></v-select>
          </v-col>
          <v-col
            cols="6"
            md="6"
          >
            <h4 class="mb-5">
              Contact
            </h4>

            <v-select
              v-model="entityData.title"
              :items="titles"
              class="mb-3"
              label="Civilité"
              outlined
            ></v-select>

            <v-text-field
              v-model="entityData.lastname"
              class="mb-5"
              label="Nom"
              outlined
              dense
              placeholder="Nom"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.firstname"
              class="mb-5"
              label="Prénom"
              outlined
              dense
              placeholder="Prénom"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.job"
              class="mb-5"
              label="Fonction"
              outlined
              dense
              placeholder="Fonction"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.email"
              class="mb-5"
              label="Email"
              outlined
              dense
              placeholder="Email"
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="entityData.phone"
              class="mb-5"
              label="Tél"
              outlined
              dense
              placeholder="Tél"
              hide-details
            ></v-text-field>

            <v-textarea
              v-model="entityData.message"
              class="mb-5"
              label="Message"
              outlined
              dense
              placeholder="Message"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'contactWorksCouncilList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import config from '../../../config'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Snackbar,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      engines: [],
      engineSelection: [],
      titles: [
        {
          text: 'M.',
          value: '5'
        },
        {
          text: 'Mme',
          value: '4'
        }
      ],
      days: [
        {
          text: 'Lundi',
          value: 'monday'
        },
        {
          text: 'Mardi',
          value: 'tuesday'
        },
        {
          text: 'Mercredi',
          value: 'wednesday'
        },
        {
          text: 'Jeudi',
          value: 'thursday'
        },
        {
          text: 'Vendredi',
          value: 'friday'
        },
        {
          text: 'Samedi',
          value: 'saturday'
        },
        {
          text: 'Dimanche',
          value: 'sunday'
        }
      ],
      contactWorksCouncil: null,
      entityData: {
        enabled: false,
        address: null,
        zipCode: null,
        city: null,
        title: null,
        lastname: null,
        firstname: null,
        job: null,
        email: null,
        phone: null,
        message: null,
        company: null,
        schedule: null,
        nbPersons: null,
        dutyDays: []
      },
      companyRules: [
        v => !!v || 'Le nom de l\'entreprise est obligatoire'
      ],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    }
  },
  created () {
    this.getContactWorksCouncil()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.putContactWorksCouncil()
      }
    },
    apiUrl () {
      return config.apiUrl
    },
    getContactWorksCouncil () {
      const contactWorksCouncilId = this.$route.params.contactWorksCouncilId

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/works-council/contacts/' + contactWorksCouncilId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.contactWorksCouncil = response.data
            this.entityData = this.contactWorksCouncil

            this.valid = true
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    putContactWorksCouncil () {
      this.loading = true
      this.valid = false
      this.successMessage = null

      Vue.prototype.$http
        .put(config.apiUrl + '/api/works-council/contacts/' + this.entityData.id, this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.contactWorksCouncil = response.data

            this.snackbar = true
            this.successMessage = 'Contact CE mis à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    formatDate (currentDate) {
      if (currentDate) {
        const timestamp = Date.parse(currentDate)
        const date = new Date(timestamp)
        let day = date.getDate()
        let month = date.getMonth() + 1

        day = (day < 10 ? '0' : '') + day
        month = (month < 10 ? '0' : '') + month

        return day + '/' + month + '/' + date.getFullYear()
      }

      return currentDate
    }
  }
}
</script>

